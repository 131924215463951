// Colors
$color-black: #0B0B0B; // Onyx
$color-dark-grey: #4c4c4c; // Antra
$color-grey: #777777; // Slate

$color-green: #3fbb87;
$color-green-075: #409d76;
$color-green-050: #407d63;
$color-green-opacity-050: rgba(63, 187, 135, 0.5);
$color-green-025: #cfeee1;

$color-light-green: #b7dfcd;
$color-blue: #204f6a;
$color-blue-royal: #3375ff;
$color-red: #ff4136;
$color-indigo: #7B61FF;
$color-red-03: rgba(255, 65, 54, 0.03);
$color-yellow: #ffcc3d;
$color-dark-turquoise: #134f50;
$color-bold-turquoise: #0e3b3b;

$color-black-10: rgba(0, 0, 0, 0.1);
$color-black-60: rgba(0, 0, 0, 0.6);
$color-grey-70: rgba(76, 76, 76, 0.7);
$color-light-grey: #9b9b9b; // Dust
$color-light-grey-50: rgba(150, 150, 150, 0.5); // Dust
$color-snow: #e5e5e5;
$color-snow-75: rgba(229, 229, 229, 0.75);
$color-dove: #f3f3f3;
$color-white: #fff;
$color-storm: #c9c9c9;
$color-railgun: #3fbb87;
$color-railgun-25: rgba(63, 187, 135, 0.25);;

// Gradients
$gradient-navi: linear-gradient(to right, #3fbb87, #204f6a);
$gradient-disruptor: linear-gradient(113deg, #136a66, #1a364f);
$gradient-robin-hood: linear-gradient(to bottom, #40c38c, #3fbb87);
$gradient-snow-storm: linear-gradient(90deg, $color-snow, $color-storm, $color-snow, $color-storm);
